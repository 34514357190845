import _isFunction from 'lodash-es/isFunction';
import _pick from 'lodash-es/pick';
import _reduce from 'lodash-es/reduce';
import _isObject from 'lodash-es/isObject';
import { UUID } from 'angular2-uuid';
import { formatBytes } from '@utils/numbers';

declare var window: any;

export function dataURLtoBlob(dataurl: string, maxFileSize: number) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  const n = bstr.length;
  if (n > maxFileSize) {
    throw new Error(`File size too large. Max ${formatBytes(maxFileSize)}`);
  }
  const u8arr = [];

  for (let i = 0; i < n; i++) {
    u8arr.push(bstr.charCodeAt(i));
  }

  return new Blob([new Uint8Array(u8arr)], { type: mime });
}

export function removeMethods(obj) {
  const props = [];

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && !_isFunction(obj[prop])) {
      props.push(prop);
    }
  }

  return _pick(obj, props);
}

export function renameKeys(data, keyMap) {
  return _reduce(
    data,
    (newData, val, oldKey) => {
      const newKey = keyMap[oldKey] ? keyMap[oldKey] : oldKey;
      newData[newKey] = val;

      return newData;
    },
    {}
  );
}

export function createTemporaryId(obj) {
  obj['_Id'] = UUID.UUID();
}

export function matchByIdFn(data) {
  return (item) => {
    if (_isObject(data)) {
      if (data.Id) {
        return item.Id === data.Id;
      } else if (item._Id) {
        return item._Id === data._Id;
      }
    } else {
      return item.Id === data || item._Id === data;
    }
  };
}

/**
 * @deprecated
 * legacy that needs to go away. Use https://rxjs-dev.firebaseapp.com/api/index/function/throwError
 */
export function throwError(err: string) {
  // setTimeout is required when throwing error from observer
  setTimeout(() => {
    throw new Error(err);
  });
}

// used to handle various error responses
export function extractErrorMsg(err: any) {
  if (_isObject(err)) {
    if (err.Message) {
      return err.Message;
    }
    if (err.error) {
      return extractErrorMsg(err.error);
    }
  } else if (typeof err === 'string') {
    return err;
  }

  const tmp = _isObject(err) ? JSON.stringify(err) : err;
  throwError(`Can't extract error message from ${tmp}`);

  return 'An error occurred. Please try again later';
}

export function versionCompare(a, b) {
  if (a === b) {
    return 0;
  }

  if (a === null && b !== null) {
    return -1;
  }

  if (a !== null && b === null) {
    return 1;
  }

  if (a === null && b === null) {
    return 0;
  }

  const a_components = a.split('.');
  const b_components = b.split('.');

  const len = Math.min(a_components.length, b_components.length);

  // loop while the components are equal
  for (let i = 0; i < len; i++) {
    // A bigger than B
    if (Number(a_components[i]) > Number(b_components[i])) {
      return 1;
    }

    // B bigger than A
    if (Number(a_components[i]) < Number(b_components[i])) {
      return -1;
    }
  }

  // If one's a prefix of the other, the longer one is greater.
  if (a_components.length > b_components.length) {
    return 1;
  }

  if (a_components.length < b_components.length) {
    return -1;
  }

  // Otherwise they are the same.
  return 0;
}

export function convertCtoF(value, precision = 2) {
  return parseFloat(((value * 9) / 5 + 32).toFixed(precision));
}

export function convertFtoC(value) {
  return (((value - 32) * 5) / 9).toFixed(2);
}

export const celciusMinMax = [0, 100];
export const fahrenheitMinMax = [32, 212];

export function capitalizeFirstLetter(model: string) {
  if (model && model.charAt(0) !== model.charAt(0).toUpperCase()) {
    return model.charAt(0).toUpperCase() + model.slice(1);
  }
  return model;
}

export function getDistanceMi(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  const R = 3963.1676; // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in miles
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
